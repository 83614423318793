<template>
  <div>
    <div class="white pt-6 pb-6 pl-8 pr-8 mb-5 d-flex justify-space-between align-center rounded-lg">
      <h1 class="primary--text font-weight-black">CLIENTES</h1>
      <div>
        <v-btn class="font-weight-regular mr-2" large depressed :outlined="!paidFilter" color="error"
          @click="$emit('changePaidFilter', !paidFilter)">Pendientes de pago</v-btn>
        <v-btn class="font-weight-regular" large depressed color="primary"
          @click="$emit('changeComponent', 'ClientNew')">NUEVO CLIENTE</v-btn>
      </div>
    </div>

    <div class="white primary--text font-weight-black pt-4 pb-4 pl-8 pr-8 d-flex align-center rounded-lg">
      <div class="info--number">NO. CLIENTE</div>
      <div class="info--name">NOMBRE</div>
      <div class="info--phone e">NO. TELÉFONO</div>
      <div class="info--address">DIRECCIÓN</div>
      <div class="info--actions text-center">ACCIONES</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientTemporalTableHead",
  props: {
    paidFilter: {
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped></style>